/*
 *
 * @file utm_to_cookie.js
 * UTM tags need to survive both registration and the change of session in the
 * case of a sign-in to an existing auth0 account. When a user lands on a page
 * with UTM tags present, we store them in a cookie that is processed by the
 * application controller if current_user is present
 *
 */

import Cookies from "js-cookie";

export default function () {
  const p = new URLSearchParams(window.location.search);
  let v = ["medium", "campaign", "term", "content", "source"];
  v.forEach((t) => {
    let tn = `utm_${t}`;
    let r = p.get(tn);
    if (r && r != "") {
      Cookies.set(tn, r.substring(0, 64));
    }
  });
}
