/*
 *
 * This is the main JS file for the Rise Web pathway.
 * It has two functions:
 *   - Instantiate Vue components based on data attributes found on the page
 *   - Provide centralized functions for cross-cutting concerns like analytics
 *
 */
import UtmToCookie from "user/utm_to_cookie.js";
import * as Analytics from "user/analytics.js";

class Application {
  constructor() {
    UtmToCookie();
    Analytics.setCookiesForMeasurementProtocol();
    Analytics.listenForFAQEvents();
    Analytics.setupMixpanel();
    this.onready = this.onready.bind(this);
    window.addEventListener("DOMContentLoaded", () => this.onready());
  }

  async onready() {
    const faqTitles = document.querySelectorAll(".faqs__item-title");

    faqTitles.forEach((itemTitle) => {
      itemTitle.addEventListener("click", () => {
        const parentItem = itemTitle.parentElement;
        parentItem.classList.toggle("faqs__item--expanded");
      });
    });

    if (document.querySelector("[data-vue-target=sch]")) {
      const { default: ScholarApp } = await import(
        /* webpackChunkName: "scholarship-app" */ "user/ScholarshipApplication"
      );
      this.app = new ScholarApp(this);
    }
    if (document.querySelector("#user-menu-button")) {
      const { default: Header } = await import(/* webpackChunkName: "header-menu" */ "layout/header.js");
      new Header(this);
    }
  }
}

new Application();
