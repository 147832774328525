/*
 *
 * @file analytics
 * Code for handling our google tag manager instance
 * Note that dataLayer pushes occur in other places (ScholarshipApplication.js)
 */

import Cookies from "js-cookie";
import mixpanel from "mixpanel-browser";

const setupMixpanel = function () {
  const MixpanelToken = window.mixpanel_token || false;

  if (MixpanelToken) {
    mixpanel.init(MixpanelToken, { debug: window.mix_panel_debug || false });
    if (window.mixpanel_identify) {
      mixpanel.identify(window.mixpanel_identify);
      mixpanel.people.set("email", window.mixpanel_email);
    }
  }
};

/*
 *
 * We need the client_id from the Gtag so that it can be sent to the back end via a cookie.
 * gtag.js doesn't support event callbacks so we'll poll for 5 seconds or until the client_id is found
 *
 */
const setCookiesForMeasurementProtocol = function () {
  const GTAG_POLL_MAX_EXECUTIONS = 10;

  /**
   *
   * Starts polling if we have a measurement_id
   *
   */
  function maybe_start_polling() {
    if (typeof window.ga_measurement_id != "undefined") {
      window.__rise_gtm_poll = setInterval(fetch_client_id, 500);
      window.__rise_gtm_poll_ex = 0;
    }
  }

  /**
   *
   * Worker function that checks for the client_id in the dataLayer
   * and unsets the interval if it finds it
   *
   */
  function fetch_client_id() {
    if (window.__rise_gtm_poll_ex > GTAG_POLL_MAX_EXECUTIONS) {
      clearInterval(window.__rise_gtm_poll);
      console.warn("gtag.js client_id not found after " + GTAG_POLL_MAX_EXECUTIONS + " attempts");
      return;
    } else {
      window.__rise_gtm_poll_ex += 1;
    }

    if (typeof window.dataLayer != "undefined") {
      clearInterval(window.__rise_gtm_poll);
      (function () {
        window.dataLayer.push(arguments);
      })("get", window.ga_measurement_id, "client_id", (id) => set_cookie_client_id(id));
    }
  }

  /*
   *
   * Create ga_client_id cookie with the ID value if the cookie was not created before
   *
   */
  function set_cookie_client_id(id) {
    const ga_client_id = Cookies.get("ga_client_id");
    if (!ga_client_id) {
      Cookies.set("ga_client_id", id);
    }
    // Also set ga_debug_mode
    if (typeof window.ga_debug_mode != "undefined") {
      if (window.ga_debug_mode) {
        Cookies.set("ga_debug_mode", "1");
      } else {
        Cookies.set("ga_debug_mode", "0");
      }
    }
  }

  // Bootstrap the polling
  if (document.readyState === "complete") {
    maybe_start_polling();
  } else {
    window.addEventListener("DOMContentLoaded", maybe_start_polling);
  }

  window.setGtmPreviewCookie = function (cookieValue) {
    Cookies.set("ga_server_preview", cookieValue);
    return "Set ga_server_preview cookie to " + cookieValue;
  };
};

/*
  Our FAQ accordions need to be instrumented. When expanded, we will
  send the select_content event to the dataLayer including the innerText of the
  clicked accordion title as the content_id. The content_type is 'faq'.
*/
const listenForFAQEvents = function () {
  // attach event listener to the document and listen for clicks
  // on elements with data attribute data-ga-event="select_content"
  // but only send the dataLayer event if the parent node has classs
  // faqs__item--expanded or

  document.addEventListener("click", function (event) {
    if (event.target.dataset.gaEvent === "faq") {
      if (event.target.parentNode.classList.contains("faqs__item--expanded")) {
        pushSelectContentEvent("faq", event.target.innerText);
      }
    }
    if (event.target.matches(".essay-question__resources a")) {
      pushSelectContentEvent("resources", event.target.innerText);
    }
  });
};

const pushSelectContentEvent = function (content_type, content_id) {
  console.log(`select_content: ${content_type}: ${content_id}`);
  if (typeof window.dataLayer === "undefined") {
    return;
  }
  window.dataLayer.push({
    event: "select_content",
    content_type: content_type,
    content_id: content_id,
  });
};

export { listenForFAQEvents, pushSelectContentEvent, setCookiesForMeasurementProtocol, setupMixpanel };
